import React from "react";
import Layout from "../components/layout";
import { graphql, Link } from "gatsby";
import { Center } from "../components/typography/styled/typography";
import { formatDate } from "../utils/date";
import { Flex, Box } from "reflexbox";
import {PostDate, BlogPost, BlogPostTitle} from "../styled/blog";
import SEO from "../components/seo";
import Tags from "../components/blog/tags";
import { Container } from "../components/layout/container";
import { Ornament } from "../components/layout/ornament";
import { Lang } from "../components/tr";

const BlogPage = ({ data, pageContext: { lang = Lang.cs }, location }) => {
  const post = data.markdownRemark;
  const { title, image } = post.frontmatter;

  return (
    <Layout lang={lang} location={location}>
      <SEO
        lang={lang}
        rawTitle={title}
        description={post.excerpt}
        image={image}
      />
      <Container>
        <Flex flexWrap={"wrap"} justifyContent={"center"}>
          <Box width={[1, 1, 3 / 4, 2 / 3, 2 / 3]} py={4}>
            <Link to={"/blog"}>{"<-"} Zpět</Link>
            <BlogPost>
              <Ornament />
              <Center>
                <BlogPostTitle>{title}</BlogPostTitle>
              </Center>

              <PostDate>{formatDate(post.frontmatter.date)}</PostDate>

              <div dangerouslySetInnerHTML={{ __html: post.html }} />

              <Tags tags={post.frontmatter.tags} />
            </BlogPost>
            <Link to={"/blog"}>{"<--"} Zpět</Link>
          </Box>
        </Flex>
      </Container>
    </Layout>
  );
};

export default BlogPage;
export const query = graphql`
  query PostQuery($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      excerpt(pruneLength: 150)
      frontmatter {
        title
        description
        date
        tags
        image
      }
    }
  }
`;
